
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import Row from '../../simple.vue'
  import Panels from '../panels'

  import { Evaluation, EvaluationCondition } from '@/entities/loans'
  import { EvaluationRequirement } from '@/entities/loans/EvaluationRequirement'
  import { ClosingReason } from '@/entities/settings'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

interface Header {
  title: string;
  icon: string;
  color: string;
  tooltip: string
}

interface EvaluationMap {
  header: Header;
  requirements?: Array<EvaluationRequirement>;
  conditions?: Array<EvaluationCondition>;
  closingReason?: ClosingReason;
  comment?: string;
}

interface EvaluationSlot {
  title: string,
  tooltip?: string,
  items: Array<string>
}

interface EvaluationPanel {
  header: Header,
  rows: Array<EvaluationSlot>
}

  const ROWS = [
    {
      target: 'requirements',
      title: 'Requisitos',
      itemText: 'description',
    },
    {
      target: 'conditions',
      title: 'Condiciones',
      itemText: 'description',
    },
    {
      target: 'closingReason',
      title: 'Motivo de cierre',
      itemText: 'type.description',
    },
    {
      target: 'quotas',
      title: 'Cuotas',
      itemText: 'quotas',
    },
    {
      target: 'comment',
      title: 'Comentario',
      itemText: 'comment',
    },
  ]

@Component({ components: { Row, ...Panels } })
  export default class FinancingContent extends Vue {
  @Prop({ type: Array, required: true }) evaluations!: Evaluation[];
  @Prop({ type: String, default: 'mdi-credit-card-outline' }) icon!: string;

  get Map (): Array<EvaluationMap> {
    const { evaluations, icon } = this
    return evaluations.map(
      ({
         agreement: { name: title },
         status: { description: tooltip, color, isPending },
         requirements,
         conditions,
         closingReason,
         offers,
         comment,
       }) => ({
        header: {
          title,
          icon,
          color: closingReason?.type.color || color,
          tooltip: closingReason?.type.description || tooltip,
          disabled: isPending,
        },
        requirements: !requirements?.length ? undefined : requirements,
        conditions: !conditions?.length ? undefined : conditions,
        comment,
        closingReason,
        quotas: offers.map(offer => {
          return { quotas: offer.description }
        }),
      })
    )
  }

  get panels (): Array<EvaluationPanel> {
    const { Map: list } = this

    return list.map(evaluation => {
      const { header, comment } = evaluation

      const rows = ROWS.map(({ title, target, itemText }) => {
        const value = getObjectValueByPath(evaluation, target)
        const items = (Array.isArray(value) ? value : [value]).filter(Boolean)
        if (!items?.length) return

        if (target === 'comment' && comment?.length) {
          return ({
            title,
            items: [comment],
          })
        }

        return ({
          title,
          items: items.map(type => getObjectValueByPath(type, itemText)),
        })
      }).filter(Boolean)

      return { header, rows }
    })
  }
  }
